export const WORDS = [
  'blast',
  'toxic',
  'rings',
  'whale',
  'naked',
  'crisp',
  'eagle',
  'honey',
  'forum',
  'pearl',
  'dazed',
  'belly',
  'suits',
  'ghost',
  'thigh',
  'merch',
  'smile',
  'dance',
  'daddy',
  'adore',
  'curls',
  'kissy',
  'movie',
  'flags',
  'tiger',
  'bunny',
  'harry',
  'clown',
  'vodka',
  'cross',
  'boxer',
  'woman',
  'baker',
  'chain',
  'angel',
  'boots',
  'stage',
  'album',
  'actor',
  'feast',
  'juice',
  'pride',
  'vinyl',
  'ferns',
  'twist',
  'mouth',
  'boobs',
  'video',
  'arias',
  'bloom',
  'loads',
  'edger',
  'yacht',
  'voice',
  'style',
  'vogue',
  'pilot',
  'model',
  'sugar',
  'noise',
  'jokes',
  'nails',
  'bingo',
  'swift',
  'arena',
  'kiwis',
  'music',
  'house',
  'robin',
  'treat',
  'chasm',
  'table',
  'booze',
  'times',
  'toxic',
  'rings',
  'whale',
  'naked',
  'crisp',
  'eagle',
  'honey',
  'forum',
  'pearl',
  'dazed',
  'belly',
  'suits',
  'ghost',
  'thigh',
  'merch',
  'smile',
  'dance',
  'daddy',
  'adore',
  'curls',
  'kissy',
  'movie',
  'flags',
  'tiger',
  'bunny',
  'harry',
  'clown',
  'vodka',
  'cross',
  'boxer',
  'woman',
  'baker',
  'chain',
  'angel',
  'boots',
  'stage',
  'album',
  'actor',
  'feast',
  'juice',
  'pride',
  'vinyl',
  'ferns',
  'twist',
  'mouth',
  'boobs',
  'video',
  'arias',
  'bloom',
  'loads',
  'edger',
  'yacht',
  'voice',
  'style',
  'vogue',
  'pilot',
  'model',
  'sugar',
  'noise',
  'jokes',
  'nails',
  'bingo',
  'swift',
  'arena',
  'kiwis',
  'music',
  'house',
  'robin',
  'treat',
  'chasm',
  'table',
  'booze',
  'times',
]